import request from '@/utils/request'

//诊断工具一览
export function searchQuestionnaire(data) {
  return request({
    url: "/diagnoseTool/toolPage",
    method: "post",
    data
  })
}

//小程序二维码
export function basicQrcode(query) {
  return request({
    url: "/diagnoseTool/basicQrcode",
    method: "get",
    responseType: "blob",
    params: query
  })
}


//诊断工具配置智参中心数据一览
export function wisdomGinsengToolPage(data) {
  return request({
    url: "/diagnoseTool/wisdomGinsengToolPage",
    method: "post",
    data
  })
}

//新增诊断工具
export function insertQuestionnaire(data) {
  return request({
    url: "/diagnoseTool/insertQuestionnaire",
    method: "post",
    data
  })
}

//诊断工具详情
export function getQuestionnaireDetail(data) {
  return request({
    url: "/diagnoseTool/getQuestionnaireDetail",
    method: "get",
    params: data
  })
}

//编辑诊断工具
export function updateQuestionnaire(data) {
  return request({
    url: "/diagnoseTool/updateQuestionnaire",
    method: "post",
    data
  })
}

//新增诊断工具标签
export function insertQuestionnaireLabel(data) {
  return request({
    url: "/diagnoseTool/insertQuestionnaireLabel",
    method: "post",
    data
  })
}

//编辑诊断工具标签
export function updateQuestionnaireLabel(data) {
  return request({
    url: "/diagnoseTool/updateQuestionnaireLabel",
    method: "post",
    data
  })
}

//诊断工具详情
export function getQuestionnaireLabelById(data) {
  return request({
    url: "/diagnoseTool/getById",
    method: "get",
    params: data
  })
}

//编辑诊断工具标签
export function updateGeneralVersion(data) {
  return request({
    url: "/diagnoseTool/updateGeneralVersion",
    method: "post",
    data
  })
}

//配置智参中心 上架
export function wisdomGinsengOnSelf(data) {
  return request({
    url: "/diagnoseTool/wisdomGinsengOnSelf",
    method: "post",
    data
  })
}

//配置智参中心 下架
export function wisdomGinsengOffSelf(data) {
  return request({
    url: "/diagnoseTool/wisdomGinsengOffSelf",
    method: "post",
    data
  })
}

//下架诊断工具
export function updateOffSelfTool(data) {
  return request({
    url: "/diagnoseTool/updateOffSelfTool",
    method: "get",
    params: data
  })
}

//上架诊断工具
export function updateOnSelfTool(data) {
  return request({
    url: "/diagnoseTool/updateOnSelfTool",
    method: "get",
    params: data
  })
}

//新增问题组
export function saveGroup(data) {
  return request({
    url: "/diagnoseTool/saveGroup",
    method: "post",
    data
  })
}

//新增问题组
export function updateGroup(data) {
  return request({
    url: "/diagnoseTool/updateGroup",
    method: "post",
    data
  })
}

//删除问题组
export function deleteGroup(data) {
  return request({
    url: "/diagnoseTool/deleteGroup",
    method: "post",
    data
  })
}

//问题组一览
export function selectGroup(data) {
  return request({
    url: "/diagnoseTool/selectGroup",
    method: "get",
    params: data
  })
}

//查询二级、三级问题组
export function selectTwoGroup(data) {
  return request({
    url: "/diagnoseTool/selectTwoGroup",
    method: "get",
    params: data
  })
}

//新增问题组和问题的关联关系
export function saveGroupQuestions(data) {
  return request({
    url: "/diagnoseTool/saveGroupQuestions",
    method: "post",
    data
  })
}

//预览问卷 查询一览
export function selectQuestions(data) {
  return request({
    url: "/diagnoseTool/selectQuestions",
    method: "get",
    params: data
  })
}

//修改问题权重与选项权重
export function updateWeight(data) {
  return request({
    url: "/diagnoseTool/updateWeight",
    method: "post",
    data
  })
}

//根据问卷id和问题id 查询选项关联政策
export function selectOptionPolicy(data) {
  return request({
    url: "/diagnoseTool/selectOptionPolicy",
    method: "get",
    params: data
  })
}

//新增选项关联政策
export function saveOptionPolicy(data) {
  return request({
    url: "/diagnoseTool/saveOptionPolicy",
    method: "post",
    data
  })
}

//修改选项关联政策
export function updateOptionPolicy(data) {
  return request({
    url: "/diagnoseTool/updateOptionPolicy",
    method: "post",
    data
  })
}

//设置问题是否必填
export function updateIsRequired(data) {
  return request({
    url: "/diagnoseTool/updateIsRequired",
    method: "post",
    data
  })
}

//批量设置条件
export function addConditions(data) {
  return request({
    url: "/diagnoseTool/addConditions",
    method: "post",
    data
  })
}

//查询设置条件
export function selectConditions(data) {
  return request({
    url: "/diagnoseTool/selectConditions",
    method: "get",
    params: data
  })
}

//批量删除问题组配置的问题
export function deleteGroupQuestions(data) {
  return request({
    url: "/diagnoseTool/deleteGroupQuestions",
    method: "post",
    data
  })
}


//查询认定条件
export function selectQuestionnaireConditions(data) {
  return request({
    url: "/questionnaire/selectQuestionnaireConditions",
    method: "get",
    params: data
  })
}

//保存认定条件
export function addQuestionnaireConditions(data) {
  return request({
    url: "/questionnaire/addQuestionnaireConditions",
    method: "post",
    data
  })
}

//模糊搜索合作伙伴
export function getPartners(data) {
  return request({
    url: "/partner/getPartners",
    method: "get",
    params: data
  })
}

// 配置合作伙伴一览
export function selectPartnerUser(data) {
  return request({
    url: '/firstTierOperator/selectPartnerUser',
    method: 'post',
    data
  })
}

// 批量关联/批量移除
export function updatePartner(data) {
  return request({
    url: '/firstTierOperator/updatePartner',
    method: 'post',
    data
  })
}
