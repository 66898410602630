<!-- 诊断工具 -->
<template>
  <div class="diagnostic_tool">
    <div class="top">
      <div class="row">
        <div class="label_txt">
          <span>工具类型：</span>
        </div>
        <el-radio-group v-model="queryInfo.evaluationType" @change="changeSearchangeSearch">
          <el-radio label="">全部</el-radio>
          <el-radio label="1">企业数智化转型诊断评估工具</el-radio>
          <el-radio label="2">企业数智化转型政策申报工具</el-radio>
          <el-radio label="3">调查问卷工具</el-radio>
        </el-radio-group>
      </div>
      <div class="row">
        <el-button type="primary" @click="addTool">新增工具</el-button>
        <el-button plain @click="showWisdomGinsengDialog">批量配置智参</el-button>
        <el-button plain @click="partnersDialog">配置合作伙伴</el-button>
        <el-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
        >
          全选
        </el-checkbox>
        <!-- 右边搜索 -->
        <div class="search_right">
          <el-input
            placeholder="请输入"
            v-model="queryInfo.queryConditions"
            class="input-with-select"
          >
            <el-button slot="append" @click="changeSearchangeSearch" type="primary">搜索</el-button>
          </el-input>
        </div>
      </div>
    </div>
    <div class="card_list">
      <div class="card" v-for="(item, index) in cardList" :key="index">
        <div class="po_imgqr" @click="getQRCode(item)">
          <img
            src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img/Group%204010.png"
            alt=""
          />
        </div>
        <div class="card_left">
          <div class="item_checkbox">
            <el-checkbox
              @change="latechange($event, item)"
              v-model="item.checked1"
              label=""
            ></el-checkbox>
          </div>
          <div class="logo_box">
            <img :src="item.image" alt="" />
          </div>
          <div class="tool_info">
            <div class="tool_name">
              <span>{{ item.questionnaireName }}</span>
            </div>
            <div class="info_box">
              <div class="row" v-for="(i1, i2) in item.toolQuestionsResultList" :key="i2">
                <div class="item">
                  <span>{{ i1.questionsType }}</span>
                  <span>{{ i1.num }}题</span>
                </div>
              </div>
            </div>
          </div>
          <div class="enterprise_count">
            <div class="num">{{ item.companyNum ? item.companyNum : 0 }}</div>
            <div class="txt">
              <span>参与企业数</span>
            </div>
          </div>
        </div>
        <div class="card_right">
          <div class="row">
            <el-button type="primary" @click="toConfigIndustry(item.id)">配置行业</el-button>
            <el-button @click="configWisdomGinseng(item.id)" plain>智参中心</el-button>
            <el-button class="btn3" @click="toManageTroubleGroup(item.id)">问题组管理</el-button>
            <span v-if="item.isShelf == '0'" class="color1" @click="updateOffSelfTool(item.id)"
              >下架</span
            >
            <span v-else class="color5" @click="updateOnSelfTool(item.id)">发布</span>
            <span class="color5" @click="editTool(item.id)">编辑</span>
            <!-- <span class="color3">删除</span> -->
          </div>
          <div class="operate">
            <span>操作人：{{ item.userName != null ? item.userName : '--' }}</span>
          </div>
          <div class="operate">
            <span>最近操作时间：{{ item.updateTime != null ? item.updateTime : '--' }}</span>
          </div>
        </div>
        <div class="pore_qr" v-show="item.qrvisible" @mouseleave="hideQRBox(item)">
          <div class="qr_top">诊断工具二维码</div>
          <div class="popover_img">
            <img :src="QRCode" alt="" />
          </div>
          <div class="qr_btn_list">
            <el-button plain size="mini" @click="imgClick(1, item)">导出介绍页</el-button>
            <el-button plain size="mini" @click="imgClick(2, item)">导出详情页</el-button>
            <el-button plain size="mini" @click="imgClick(3, item)">导出列表页</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <!-- 新增工具弹窗 -->
    <el-dialog
      :title="echo == '0' ? '新增工具' : '编辑工具'"
      :visible.sync="addToolDialogVisible"
      :before-close="addToolDialogClosed"
      :close-on-click-modal="false"
      width="60%"
    >
      <div class="dialog_content">
        <el-form
          label-width="120px"
          ref="addToolFormRef"
          :model="addToolForm"
          :rules="addToolFormRules"
        >
          <el-form-item label="诊断工具类型">
            <el-radio-group v-model="addToolForm.evaluationType">
              <el-radio label="1">企业数智化转型诊断评估工具</el-radio>
              <el-radio label="2">企业数智化转型政策申报工具</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="头像" prop="image">
            <div class="img_box">
              <img-big-upload
                :imageShow.sync="addToolForm.image"
                :addTitleShow="false"
                @removeImg="removeImgImage"
                @uploadOneImgShow="uploadOneImgShowImage"
              />
              <span class="color4"
                >此图片应用后台工具列表页，建议上传图片整体尺寸为80*80px，应小于4MB</span
              >
            </div>
          </el-form-item>
          <el-form-item label="诊断工具名称" prop="questionnaireName">
            <div class="input_box">
              <el-input
                v-model="addToolForm.questionnaireName"
                placeholder="请输入诊断工具名称"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="一句话介绍" prop="toolBrief">
            <div class="input_box">
              <el-input v-model="addToolForm.toolBrief" placeholder="请输入一句话介绍"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="诊断依据">
            <div class="input_box">
              <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 6 }"
                v-model="addToolForm.diagnosticBasis"
                placeholder="请输入诊断依据"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="封面图">
            <div class="img_box">
              <img-big-upload
                :imageShow.sync="addToolForm.coverImg"
                :addTitleShow="false"
                @removeImg="removeImgCoverImg"
                @uploadOneImgShow="uploadOneImgShowCoverImg"
              />
              <span class="color4"
                >此图片应用小程序工具列表页，建议上传图片整体尺寸为340*80px，应小于6MB</span
              >
            </div>
          </el-form-item>
          <el-form-item label="适用范围">
            <div class="input_box">
              <el-input
                v-model="addToolForm.scopeApplication"
                placeholder="请输入适用范围"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="自诊时间">
            <div class="row">
              <div class="mini_input">
                <el-input
                  v-model.number="addToolForm.selfDiagnosisTime"
                  placeholder="请输入数字"
                ></el-input>
              </div>
              <div>
                <span>分钟</span>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="分析报告">
            <div style="display: flex">
              <div class="mini_input">
                <el-input
                  v-model.number="addToolForm.analysisReportStart"
                  placeholder="请输入数字"
                ></el-input>
              </div>
              <div class="mini_input">
                <el-input
                  v-model.number="addToolForm.analysisReportEnd"
                  placeholder="请输入数字"
                ></el-input>
              </div>
              <div>
                <span>小时</span>
              </div>
            </div>
            <div class="row">
              <span class="color4"> (设置用户填写诊断问卷后至分析报告出炉的时间段) </span>
            </div>
          </el-form-item>
          <el-form-item label="分享链接标语">
            <div class="input_box">
              <el-input
                v-model="addToolForm.appLinkSlogan"
                placeholder="请输入一句话介绍"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="分享链接图片">
            <div class="img_box">
              <img-big-upload
                :imageShow.sync="addToolForm.appLinkPicture"
                :addTitleShow="false"
                @removeImg="removeImgAppLinkPicture"
                @uploadOneImgShow="uploadOneImgShowAppLinkPicture"
              />
              <span class="color4"
                >(此图片应用小程序分享链接，建议整体尺寸为630*510px，图片应小于6MB)
              </span>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveQuestionnaire">保存</el-button>
        <el-button @click="addToolDialogClosed">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 批量配置智参中心 -->
    <el-dialog
      title="批量配置智参中心"
      :visible.sync="manyConfigDialogVisible"
      :before-close="wisdomGinsengDialogClosed"
      :close-on-click-modal="false"
      width="60%"
    >
      <div class="dialog_box">
        <div class="row">
          <city-select
            ref="child"
            :province="queryWisdomGinseng.province"
            :city="queryWisdomGinseng.city"
            @searchProvince="searchProvince"
            @searchCity="searchCity"
          />
        </div>
        <div class="row">
          <div class="search_right">
            <el-input
              placeholder="请输入智参中心名称"
              v-model="queryWisdomGinseng.queryConditions"
              class="input-with-select"
              style="width: 440px"
            >
              <el-button slot="append" @click="searchWisdomGinseng">搜索</el-button>
            </el-input>
          </div>
        </div>
        <div class="table">
          <el-table
            :data="wisdomGinsengTableData"
            style="width: 100%"
            :header-cell-style="{ background: '#F8F9FB' }"
            @selection-change="wisdomGinsengSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="zcName" label="智参中心名称" min-width="140"> </el-table-column>
            <el-table-column label="所在地区" min-width="140">
              <template v-slot="{ row }">
                <span>{{ row.province }}</span>
                <span>-</span>
                <span>{{ row.city }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="new_page">
        <el-pagination
          :current-page="queryWisdomGinseng.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="queryWisdomGinseng.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="wisdomGinsengTableDataTotal"
          @size-change="handleWisdomGinsengSizeChange"
          @current-change="handleWisdomGinsengCurrentChange"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="dialog_btn_list">
          <el-button @click="wisdomGinsengDialogClosed">取消</el-button>
          <el-button type="primary" @click="wisdomGinsengUpdate">上架</el-button>
        </div>
      </span>
    </el-dialog>
    <!-- 配置智参中心 -->
    <el-dialog
      title="配置智参中心"
      :visible.sync="configDialogVisible"
      @closed="wisdomGinsengDialogClosed"
      :close-on-click-modal="false"
      width="60%"
    >
      <div class="dialog_box">
        <div class="row">
          <city-select
            ref="child"
            :province="queryWisdomGinseng.province"
            :city="queryWisdomGinseng.city"
            @searchProvince="onlySearchProvince"
            @searchCity="onlySearchCity"
          />
        </div>
        <div class="row">
          <div class="search_right">
            <el-input
              placeholder="请输入智参中心名称"
              v-model="queryWisdomGinseng.queryConditions"
              class="input-with-select"
              style="width: 440px"
            >
              <el-button slot="append" @click="searchWisdomGinseng">搜索</el-button>
            </el-input>
          </div>
        </div>
        <div class="row">
          <el-button type="primary" @click="wisdomGinsengOnSelf">批量上架</el-button>
          <el-button plain @click="wisdomGinsengOffSelf">批量下架</el-button>
        </div>
        <div class="table">
          <el-table
            :data="wisdomGinsengTableData"
            style="width: 100%"
            :header-cell-style="{ background: '#F8F9FB' }"
            @selection-change="onlyWisdomGinsengSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="zcName" label="智参中心名称" min-width="120"> </el-table-column>
            <el-table-column label="所在地区" min-width="120">
              <template v-slot="{ row }">
                <span>{{ row.province }}</span>
                <span>-</span>
                <span>{{ row.city }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="zcName" label="操作人" min-width="120"> </el-table-column>
            <el-table-column prop="updateTime" label="操作时间" min-width="140"> </el-table-column>
            <el-table-column label="操作" min-width="80">
              <template v-slot="{ row }">
                <div class="span_btn_list">
                  <span
                    v-if="row.isShelf == '1'"
                    @click="wisdomGinsengOnSelf(row.schemaName)"
                    class="color5"
                    >上架</span
                  >
                  <span v-else @click="wisdomGinsengOffSelf(row.schemaName)" class="color6"
                    >下架</span
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="new_page">
        <el-pagination
          :current-page="queryWisdomGinseng.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="queryWisdomGinseng.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="wisdomGinsengTableDataTotal"
          @size-change="onlyHandleWisdomGinsengSizeChange"
          @current-change="onlyHandleWisdomGinsengCurrentChange"
        />
      </div>
    </el-dialog>

    <!-- 配置合作伙伴-->
    <el-dialog
      title="配置合作伙伴"
      :visible.sync="partnersDialogVisible"
      @closed="partnersDialogClosed"
      :close-on-click-modal="false"
      width="60%"
    >
      <div class="dialog_box par_box">
        <el-form ref="parFormRef" :model="partnersQueryInfo" label-width="120">
          <el-form-item label="所在地区：">
            <city-select
              ref="child"
              :province="partnersQueryInfo.province"
              :city="partnersQueryInfo.city"
              @searchProvince="parSearchProvince"
              @searchCity="parSearchCity"
            />
          </el-form-item>
          <el-form-item label="合作伙伴名称：">
            <div class="row">
              <div class="search_right">
                <el-input
                  placeholder="请输入运营商/合作伙伴名称"
                  v-model="partnersQueryInfo.name"
                  class="input-with-select"
                  style="width: 440px"
                >
                  <el-button slot="append" @click="getPartner">搜索</el-button>
                </el-input>
              </div>
            </div>
          </el-form-item>
          <div class="form_flex_par">
            <div class="row">
              <el-button type="primary" @click="parAssociation">批量关联</el-button>
              <el-button plain @click="parClose">批量移除</el-button>
            </div>

            <div class="form_flex_par_right">
              <el-form-item label="伙伴类型：">
                <el-radio-group v-model="partnersQueryInfo.isPartner">
                  <el-radio label="1">合作伙伴</el-radio>
                  <el-radio label="2">运营商</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </div>
        </el-form>
        <div class="table">
          <el-table
            :data="parList"
            style="width: 100%"
            :header-cell-style="{ background: '#F8F9FB' }"
            @selection-change="parSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="name" label="合作伙伴名称" min-width="120"> </el-table-column>
            <el-table-column label="所在地区" min-width="120">
              <template v-slot="{ row }">
                <span>{{ row.province }}</span>
                <span>-</span>
                <span>{{ row.city }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="userName" label="操作人" min-width="120"> </el-table-column>
            <el-table-column prop="updateTime" label="操作时间" min-width="140"> </el-table-column>
            <el-table-column label="操作" min-width="80">
              <template v-slot="{ row }">
                <div class="span_btn_list">
                  <span v-if="row.isRelevance == '0'" @click="parOnSelf(row)" class="color5"
                    >关联</span
                  >
                  <span v-else @click="parOffSelf(row)" class="color6">移除</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="new_page" v-if="parTotal">
        <el-pagination
          :current-page="partnersQueryInfo.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="partnersQueryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="parTotal"
          @size-change="parSizeChange"
          @current-change="parCurrentChange"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getQRCode } from '@/api/activity'
import {
  basicQrcode,
  searchQuestionnaire,
  insertQuestionnaire,
  updateQuestionnaire,
  getQuestionnaireDetail,
  updateGeneralVersion,
  wisdomGinsengOnSelf,
  wisdomGinsengOffSelf,
  wisdomGinsengToolPage,
  updateOnSelfTool,
  updateOffSelfTool,
  selectPartnerUser,
  updatePartner
} from '@/api/diagnoseTool.js'
import { domGinsengPage } from '@/api/content.js'
import CitySelect from '@/components/CitySelect.vue'
import { apiDownloadFile } from '@/api/common.js'

//合作伙伴一览查询
const defaultPartnersQueryInfo = Object.freeze({
  city: '',
  isPartner: '1',
  name: '',
  orderByParam: '',
  pageNum: 1,
  pageSize: 10,
  province: '',
  questionnaireIds: [],
  sort: 'desc'
})

//工具列表 查询
const defaultQueryInfo = Object.freeze({
  evaluationType: '', //评估类型(1:企业数智化转型诊断评估,2:企业数智化转型政策申报)
  orderByParam: '', //排序字段+ DESC或ASC
  pageNum: 1,
  pageSize: 10,
  queryConditions: '' //模糊查询字段
})
//新增工具填写的表单
const defaultAddForm = Object.freeze({
  diagnosticBasis: '',
  appLinkPicture: '',
  appLinkSlogan: '',
  coverImg: '',
  createId: null,
  createTime: '',
  evaluationType: '1',
  id: null,
  image: '',
  isAnalysisChart: '0',
  policyIdList: '',
  questionnaireHint: '',
  questionnaireName: '',
  toolBrief: '',
  updateId: null,
  updateTime: '',
  scopeApplication: '',
  selfDiagnosisTime: null,
  analysisReportStart: null,
  analysisReportEnd: null
})
//批量配置智参 查询
const defaultQueryWisdomGinseng = Object.freeze({
  city: '',
  id: null,
  orderByParam: '',
  pageNum: 1,
  pageSize: 10,
  province: '',
  queryConditions: ''
})

export default {
  name: 'diagnosticTool',
  components: {
    CitySelect
  },
  data() {
    return {
      parIdsList: [], //合作伙伴选择的id集合
      parList: [], //合作伙伴的数据
      parTotal: 0, //合作伙伴的数据条数
      partnersQueryInfo: { ...defaultPartnersQueryInfo }, //合作伙伴查询条件
      QRCode: '', //签到二维码
      qrvisible: true, //显示签到二维码
      total: 0,
      checkAll: false, //显示全选图标
      isIndeterminate: false, //显示半选图标
      wisdomGinsengTableDataTotal: 0, //批量配置智参 总条数
      queryInfo: { ...defaultQueryInfo }, //工具列表 查询
      queryWisdomGinseng: { ...defaultQueryWisdomGinseng }, //批量配置智参 查询
      wisdomGinsengTableData: [], //批量配置智参 表格
      cardList: [], //卡片数据
      echo: '', //新增工具为'0',编辑工具为'1'
      addToolForm: { ...defaultAddForm }, //新增工具填写的表单
      addToolFormRules: {
        questionnaireName: [
          { required: true, message: '请输入工具名称', trigger: 'blur' },
          { min: 2, message: '请输入2个以上的字符', trigger: 'blur' }
          // { min: 2, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        toolBrief: [{ required: true, message: '请输入一句话介绍', trigger: 'blur' }],
        image: [{ required: true, message: '请上传工具头像', trigger: 'blur' }]
      }, //新增工具表单验证规则
      partnersDialogVisible: false, //配置合作伙伴弹窗
      addToolDialogVisible: false, //新增工具弹窗
      manyConfigDialogVisible: false, //批量配置智参中心弹窗
      configDialogVisible: false, //配置智参中心弹窗
      ids: [], //批量配置智参  上架传参
      idsList: [], //选中的诊断工具集合
      schemaNames: [], //批量配置智参  上架传参
      questionnaireId: '', //
      type: '1' //临时
    }
  },
  created() {
    // this.search()
    if(this.$route.query.changeNum){
      this.queryInfo.pageNum = Number(localStorage.getItem('paginationCurrentPage'))
      this.search()
    }else{
      this.queryInfo.pageNum = 1
      this.search()
    }
  },
  methods: {
    // 关联合作伙伴
    async parOnSelf(row) {
      const query = {
        isPartner: row.isPartner,
        isRelevance: '1',
        partnerIds: [row.id],
        questionnaireIds: this.partnersQueryInfo.questionnaireIds
      }
      const { data: res } = await updatePartner(query)
      if (res.resultCode === 200) {
        this.$message.success('关联成功')
        this.parIdsList = []
        this.parListSele = []
        this.getPartner()
      } else {
        this.$message.error('网络错误，请联系管理员。')
      }
    },
    // 移除合作伙伴
    async parOffSelf(row) {
      const query = {
        isPartner: row.isPartner,
        isRelevance: '0',
        partnerIds: [row.id],
        questionnaireIds: this.partnersQueryInfo.questionnaireIds
      }
      const { data: res } = await updatePartner(query)
      if (res.resultCode === 200) {
        this.$message.success('移除成功')
        this.parIdsList = []
        this.parListSele = []
        this.getPartner()
      } else {
        this.$message.error('网络错误，请联系管理员。')
      }
    },
    //选中的合作伙伴
    parSelectionChange(selections) {
      this.parListSele = selections
      this.parIdsList = selections.map((el) => {
        return el.id
      })
    },
    //监听pagesize变化 配置智参中心
    parSizeChange(newSize) {
      this.partnersQueryInfo.pageSize = newSize
      this.getPartner()
    },
    //监听页码变化 配置智参中心
    parCurrentChange(newCurrent) {
      this.partnersQueryInfo.pageNum = newCurrent
      this.getPartner()
    },
    // 批量关联
    async parAssociation() {
      if (this.parIdsList) {
        const query = {
          isPartner: this.partnersQueryInfo.isPartner,
          isRelevance: '1',
          partnerIds: this.parIdsList,
          questionnaireIds: this.partnersQueryInfo.questionnaireIds
        }
        const { data: res } = await updatePartner(query)
        if (res.resultCode === 200) {
          this.$message.success('关联成功')
          this.parIdsList = []
          this.parListSele = []
          this.getPartner()
        } else {
          this.$message.error('网络错误，请联系管理员。')
        }
      } else {
        this.$message.warning('请选择您要关联的用户')
      }
    },
    // 批量移除
    async parClose() {
      if (this.parIdsList) {
        const query = {
          isPartner: this.partnersQueryInfo.isPartner,
          isRelevance: '0',
          partnerIds: this.parIdsList,
          questionnaireIds: this.partnersQueryInfo.questionnaireIds
        }
        const { data: res } = await updatePartner(query)
        if (res.resultCode === 200) {
          this.$message.success('移除成功')
          this.parIdsList = []
          this.parListSele = []
          this.getPartner()
        } else {
          this.$message.error('网络错误，请联系管理员。')
        }
      } else {
        this.$message.warning('请选择您要移除的用户')
      }
    },
    // 获取合作伙伴的数据
    async getPartner() {
      this.partnersQueryInfo.questionnaireIds = this.ids
      const { data: res } = await selectPartnerUser(this.partnersQueryInfo)
      if (res.resultCode === 200) {
        this.parList = res.data.list
        this.parTotal = res.data.total
      }
    },
    // 打开配置合作伙伴的弹窗
    partnersDialog() {
      if (this.ids.length > 0) {
        this.getPartner()
        this.checkAll = false
        this.isIndeterminate = false
        this.partnersDialogVisible = true
      } else {
        this.$message.warning('请先勾选诊断工具!')
      }
    },
    //返回省 配置智参中心
    parSearchProvince(val) {
      this.partnersQueryInfo.province = val
      this.getPartner()
    },
    //返回城市 配置智参中心
    parSearchCity(val) {
      this.partnersQueryInfo.city = val
      this.getPartner()
    },
    //合作伙伴弹窗关闭
    partnersDialogClosed() {
      this.partnersQueryInfo = { ...defaultPartnersQueryInfo }
      this.ids = []
      this.cardList.forEach((item) => {
        this.$set(item, 'checked1', false)
      })
      this.configDialogVisible = false
    },
    //查询工具列表
    async search() {
      const { data: res } = await searchQuestionnaire(this.queryInfo)
      if (res.resultCode === 200) {
        this.cardList = res.data.list
        this.total = res.data.total
        this.handleCheckAllChange(false)
      } else {
        this.$message.error('获取诊断工具列表失败!')
      }
    },
    // 隐藏二维码弹窗
    hideQRBox(item) {
      setTimeout(() => {
        this.$set(item, 'qrvisible', false)
      }, 2000)
    },
    //获取签到二维码
    async getQRCode(item) {
      // 展示的是详情页的二维码图片

      const { id, questionnaireName, evaluationType } = item
      const query = {
        sceneStr: `/pages/index/index_texin/index?typeid=${id}&name=${questionnaireName}&evaluationType=${evaluationType}`
      }
      //  basicQrcode
      // console.log("query",query);
      const res = await getQRCode(query)
      if (res.data) {
        console.log(123,res.data);
        this.$set(item, 'qrvisible', true)
        let blob = new Blob([res.data])
        let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
        const a = document.createElement('a') //创建a标签
        a.style.display = 'none'
        // console.log(href)
        a.href = href // 指定下载'链接

        this.QRCode = res.data.data
        // a.download = `${item.activityName}.png` //指定下载文件名
        // // a.download = "test.png";
        // a.click() //触发下载
        // window.URL.revokeObjectURL(a.href) //释放URL对象
        // if (document.body.contains(a)) {
        //   document.body.removeChild(a) //释放标签
        // }
      } else {
        this.$message.error('请求失败!')
      }
    },
    //下载二维码
    async imgClick(val, item) {
      // console.log("val",val,"item",item.image);
      // val：1是介绍页；2是详情页；3是列表页
      let txt, query
      const { id, questionnaireName, evaluationType } = item
      // 获取文件名和二维码url
      if (val === 1) {
        txt = questionnaireName + '介绍页'
        query = {
          sceneStr: '/pages/index/index_subject/index?typeid' + 1
        }
      } else if (val === 2) {
        txt = questionnaireName + '详情页'
        query = {
          sceneStr: `/pages/index/index_texin/index?typeid=${id}&name=${questionnaireName}&evaluationType=${evaluationType}`
        }
      } else {
        txt = questionnaireName + '列表页'
        query = {
          sceneStr: '/pages/index/index_diagnosis/index'
        }
      }
      const res = await apiDownloadFile({ urlStr: item.image })
      if (res.data) {
        const blob = new Blob([res.data])
        const a = document.createElement('a')
        const href = window.URL.createObjectURL(blob) // 创建下载连接
        a.href = href
        a.download = decodeURI('下载的二维码.png')
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a) // 下载完移除元素
        window.URL.revokeObjectURL(href) // 释放掉blob对象
      }else {
        this.$message.error('请求失败!')
      }
      //   basicQrcode
      // const res = await getQRCode(query)
      // if (res.data) {
      //   console.log("二维码",res.data);
      //   let blob = new Blob([res.data])
      //   let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
      //   const a = document.createElement('a') //创建a标签
      //   a.style.display = 'none'
      //   // console.log(href)
      //   a.href = href // 指定下载'链接
      //   a.download = `${txt}.png` //指定下载文件名
      //   a.click() //触发下载
      //   window.URL.revokeObjectURL(a.href) //释放URL对象
      //   if (document.body.contains(a)) {
      //     document.body.removeChild(a) //释放标签
      //   }
      // } else {
      //   console.log("二维码失败");
      //   this.$message.error('请求失败!')
      // }
    },
    //监听pagesize变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.search()
    },
    //监听页码变化
    handleCurrentChange(newCurrent) {
      this.queryInfo.pageNum = newCurrent
      localStorage.setItem('paginationCurrentPage', newCurrent)
      this.search()
    },
    //查询智参中心
    async searchWisdomGinseng(val) {
      if (val == 'only') {
        //单个配置智参中心
        const { data: res } = await wisdomGinsengToolPage(this.queryWisdomGinseng)
        if (res.resultCode === 200) {
          this.wisdomGinsengTableData = res.data.list
          this.wisdomGinsengTableDataTotal = res.data.total
        } else {
          this.$message.error('获取智参中心列表失败!')
        }
      } else {
        //批量配置智参中心
        const { data: res } = await domGinsengPage(this.queryWisdomGinseng)
        if (res.resultCode === 200) {
          this.wisdomGinsengTableData = res.data.list
          this.wisdomGinsengTableDataTotal = res.data.total
        } else {
          this.$message.error('获取智参中心列表失败!')
        }
      }
    },
    //下架诊断工具
    async updateOffSelfTool(id) {
      const { data: res } = await updateOffSelfTool({ id })
      if (res.resultCode === 200) {
        this.search()
        this.$message.success('下架工具成功!')
      } else {
        this.$message.error('下架工具失败!')
      }
    },
    //上架诊断工具  发布
    async updateOnSelfTool(id) {
      const { data: res } = await updateOnSelfTool({ id })
      if (res.resultCode === 200) {
        this.search()
        this.$message.success('发布工具成功!')
      } else {
        this.$message.error('发布工具失败!')
      }
    },
    //全选框
    handleCheckAllChange(bol) {
      //bol为布尔值，true是选中，false是取消
      if (bol) {
        this.cardList.forEach((item) => {
          this.ids.push(item.id)
          this.idsList.push(item)
          this.$set(item, 'checked1', true)
        })
      } else {
        this.cardList.forEach((item) => {
          this.ids = []
          this.idsList = []
          this.$set(item, 'checked1', false)
        })
      }
      this.isIndeterminate = false
    },
    // 多选框选中数据
    latechange(bol, row) {
      const index = this.ids.indexOf(row.id)
      if (index == -1) {
        this.ids.push(row.id)
        this.idsList.push(row)
      } else {
        this.ids.splice(index, 1)
        this.idsList.splice(index, 1)
      }
      if (bol) {
        if (this.ids.length == this.cardList.length) {
          this.isIndeterminate = false
          this.checkAll = true
        } else {
          this.isIndeterminate = true
          this.checkAll = false
        }
      } else {
        if (this.ids.length < 1) {
          this.isIndeterminate = false
          this.checkAll = false
        } else {
          this.isIndeterminate = true
          this.checkAll = false
        }
      }
    },
    //新增诊断工具
    addTool() {
      this.$router.push({
        name: 'addDiagnosticTool'
      })
    },
    //编辑诊断工具
    editTool(id) {
      this.$router.push({
        name: 'addDiagnosticTool',
        query: {
          id
        }
      })
    },
    //新增、编辑诊断工具保存
    saveQuestionnaire() {
      this.$refs['addToolFormRef'].validate(async (valid) => {
        if (valid) {
          if (this.echo == '0') {
            const { data: res } = await insertQuestionnaire(this.addToolForm)
            if (res.resultCode === 200) {
              this.$message.success('新增诊断工具成功!')
              this.addToolDialogVisible = false
              this.addToolForm = { ...defaultAddForm }
              this.search()
            } else {
              this.$message.error('新增诊断工具失败!')
            }
          } else {
            const { data: res } = await updateQuestionnaire(this.addToolForm)
            if (res.resultCode === 200) {
              this.$message.success('编辑诊断工具成功!')
              this.addToolDialogVisible = false
              this.addToolForm = {}
              this.search()
            } else {
              this.$message.error('编辑诊断工具失败!')
            }
          }
        } else {
          this.$message.error('校验不通过')
          return false
        }
      })
    },
    //新增、编辑诊断工具弹窗关闭
    addToolDialogClosed() {
      this.addToolForm = { ...defaultAddForm }
      this.addToolDialogVisible = false
    },
    //删除头像
    removeImgImage(val) {
      this.addToolForm.image = ''
    },
    //添加头像
    uploadOneImgShowImage(val) {
      this.addToolForm.image = val
    },
    //删除封面图
    removeImgCoverImg(val) {
      this.addToolForm.coverImg = ''
    },
    //添加封面图
    uploadOneImgShowCoverImg(val) {
      this.addToolForm.coverImg = val
    },
    //删除分享链接图片
    removeImgAppLinkPicture(val) {
      this.addToolForm.appLinkPicture = ''
    },
    //添加分享链接图片
    uploadOneImgShowAppLinkPicture(val) {
      this.addToolForm.appLinkPicture = val
    },
    //打开批量配置智参中心弹窗
    showWisdomGinsengDialog() {
      if (this.ids.length > 0) {
        this.searchWisdomGinseng('many')
        this.checkAll = false
        this.isIndeterminate = false
        this.manyConfigDialogVisible = true
      } else {
        this.$message.warning('请先勾选诊断工具!')
      }
    },
    //打开配置智参中心弹窗
    configWisdomGinseng(id) {
      this.questionnaireId = id
      this.queryWisdomGinseng.id = id
      this.searchWisdomGinseng('only')
      this.configDialogVisible = true
    },
    //批量配置智参中心 上架按钮
    async wisdomGinsengUpdate() {
      let schemaNames = []
      if (this.schemaNames.length > 0) {
        this.schemaNames.forEach((item) => {
          schemaNames.push(item.schemaName)
        })
      }
      const { data: res } = await updateGeneralVersion({
        ids: this.ids,
        schemaNames
      })
      if (res.resultCode === 200) {
        this.$message.success('智参中心上架成功!')
        this.search()
        this.cardList.forEach((item) => {
          this.$set(item, 'checked1', false)
        })
        this.manyConfigDialogVisible = false
        this.ids = []
        this.schemaNames = []
      } else {
        this.$message.error('智参中心上架失败!')
      }
    },
    //批量、单个 配置智参中心弹窗关闭
    wisdomGinsengDialogClosed() {
      this.queryWisdomGinseng = { ...defaultQueryWisdomGinseng }
      this.ids = []
      this.schemaNames = []
      this.cardList.forEach((item) => {
        this.$set(item, 'checked1', false)
      })
      this.configDialogVisible = false
      this.manyConfigDialogVisible = false
    },
    //返回省 批量配置智参中心
    searchProvince(val) {
      this.queryWisdomGinseng.province = val
      this.searchWisdomGinseng('many')
    },
    //返回城市 批量配置智参中心
    searchCity(val) {
      this.queryWisdomGinseng.city = val
      this.searchWisdomGinseng('many')
    },
    //选中的智参中心 批量配置智参中心
    wisdomGinsengSelectionChange(selections) {
      this.schemaNames = selections
    },
    //监听pagesize变化 批量配置智参中心
    handleWisdomGinsengSizeChange(newSize) {
      this.queryWisdomGinseng.pageSize = newSize
      this.searchWisdomGinseng('many')
    },
    //监听页码变化 批量配置智参中心
    handleWisdomGinsengCurrentChange(newCurrent) {
      this.queryWisdomGinseng.pageNum = newCurrent
      this.searchWisdomGinseng('many')
    },
    //返回省 配置智参中心
    onlySearchProvince(val) {
      this.queryWisdomGinseng.province = val
      this.searchWisdomGinseng('only')
    },
    //返回城市 配置智参中心
    onlySearchCity(val) {
      this.queryWisdomGinseng.city = val
      this.searchWisdomGinseng('only')
    },
    //选中的智参中心 配置智参中心
    onlyWisdomGinsengSelectionChange(selections) {
      this.schemaNames = selections
    },
    //监听pagesize变化 配置智参中心
    onlyHandleWisdomGinsengSizeChange(newSize) {
      this.queryWisdomGinseng.pageSize = newSize
      this.searchWisdomGinseng('only')
    },
    //监听页码变化 配置智参中心
    onlyHandleWisdomGinsengCurrentChange(newCurrent) {
      this.queryWisdomGinseng.pageNum = newCurrent
      this.searchWisdomGinseng('only')
    },
    //批量上架  配置智参中心
    async wisdomGinsengOnSelf(val) {
      let schemaNames = []
      if (this.schemaNames.length > 0) {
        this.schemaNames.forEach((item) => {
          schemaNames.push(item.schemaName)
        })
      }
      if (typeof val == 'string') {
        schemaNames.push(val)
      }
      const { data: res } = await wisdomGinsengOnSelf({
        questionnaireId: this.questionnaireId,
        schemaNames
      })
      if (res.resultCode === 200) {
        this.$message.success('上架成功!')
        this.searchWisdomGinseng('only')
      } else {
        this.$message.error('上架失败!')
      }
    },
    //批量下架  配置智参中心
    async wisdomGinsengOffSelf(val) {
      let schemaNames = []
      if (this.schemaNames.length > 0) {
        this.schemaNames.forEach((item) => {
          schemaNames.push(item.schemaName)
        })
      }
      if (typeof val == 'string') {
        schemaNames.push(val)
      }
      const { data: res } = await wisdomGinsengOffSelf({
        questionnaireId: this.questionnaireId,
        schemaNames
      })
      if (res.resultCode === 200) {
        this.$message.success('下架成功!')
        this.searchWisdomGinseng('only')
      } else {
        this.$message.error('下架失败!')
      }
    },
    //前往问题组管理界面
    toManageTroubleGroup(id) {
      this.$router.push({
        name: 'manageTrouble',
        path: '/diagnosis/managetrouble',
        query: { id }
      })
    },
    //前往配置行业界面
    toConfigIndustry(id) {
      this.$router.push({
        name: 'configIndustry',
        query: { id }
      })
    },
    // 搜索
    changeSearchangeSearch() {
      this.queryInfo.pageNum = 1
      this.search()
    }
  }
}
</script>

<style lang="less" scoped>
.par_box {
  ::v-deep .el-form-item {
    .el-input {
      width: 100% !important;
    }
  }
}
.form_flex_par {
  width: 100%;
  display: flex;
  align-items: center;

  .form_flex_par_right {
    flex: 1;
    margin-left: 40px;
  }
}
.diagnostic_tool {
  height: inherit;
}
span {
  font-size: 14px;
  color: #333;
}
.top {
  // display: flex;
  // flex-flow: column;
  // align-items: center;
  margin-bottom: 20px;
  .label_txt {
    min-width: 70px;
    margin-left: 10px;
  }
  .el-radio {
    margin-right: 10px;
  }
  .el-checkbox {
    margin-left: 10px;
  }
  .search_right {
    margin-left: auto;
    width: 400px;
  }
}
.card {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 8px;
  position: relative;
  .po_imgqr {
    position: absolute;
    right: 0px;
    top: 0px;
    cursor: pointer;
    img {
      width: 50px;
      height: 50px;
    }
  }
  .pore_qr {
    width: 200px;
    padding: 12px;
    box-sizing: border-box;
    border-radius: 8px;
    position: absolute;
    right: 40px;
    top: 40px;
    z-index: 999 !important;
    background-color: #fff;
    box-shadow: 0px 0px 8px 0px #0000000d;

    .popover_img {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      img {
        width: 160px;
        height: 160px;
      }
    }
    .qr_top {
      margin-bottom: 10px;
      color: #4e93fb;
    }
    .qr_btn_list {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      ::v-deep .el-button {
        width: 80px;
        padding: 10px;
        box-sizing: border-box;
        margin: 0;
        margin-bottom: 10px;
        border: #eaeaea 1px solid;
      }
    }
  }
  .card_left {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    .item_checkbox {
      line-height: 36px;
      margin: 0px 10px;
    }
    .txt {
      min-width: 100px;
    }
  }
  .card_right {
    display: flex;
    flex-flow: column;
    padding-right: 40px;
    box-sizing: border-box;
    .row span {
      cursor: pointer;
      margin-left: 10px;
    }
    .operate {
      display: flex;
      margin-bottom: 10px;
      span {
        margin-left: auto;
        color: #999;
      }
    }
  }
}
.logo_box {
  overflow: hidden;
  width: 100px;
  min-width: 100px;
  height: 100px;
  margin-right: 20px;
  border-radius: 50%;
  background-color: #d4d4d4;
  img {
    width: 100px;
    height: 100px;
  }
}
.tool_info {
  display: flex;
  flex-flow: column;
  width: 500px;
  padding: 0 10px;
  border-right: #e8e8e8 1px solid;
  box-sizing: border-box;
  .tool_name {
    margin-bottom: 16px;
    span {
      font-weight: 500;
      font-size: 18px;
      font-family: 'PingFang SC';
      color: #333;
    }
  }
  .info_box {
    max-height: 90px;
    display: flex;
    flex-flow: column wrap;
    .row {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .item {
        display: flex;
        justify-content: space-between;
        min-width: 140px;
        span {
          color: #999;
        }
      }
    }
  }
}
.enterprise_count {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  .num {
    font-weight: 500;
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
  }
  span {
    font-size: 20px;
    color: #333;
  }
}
.dialog_content {
  display: flex;
  width: 100%;
  max-height: 60vh;
  overflow-y: auto;

  /* 滚动条整体部分 */
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  /* 滚动条里面的滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 20px;
    transition: background-color 0.3s;
  }
}
.dialog_btn_list {
  display: flex;
  align-items: center;
  justify-content: center;
}
.row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.btn3 {
  color: #999999;
  background-color: #f4f4f4;
}

.el-form-item ::v-deep .el-input {
  width: 400px;
}
.search_right {
  width: 500px;
  ::v-deep .el-button {
    border: 1px solid #448aff;
    color: #fff;
    border-radius: 0;
    background-color: #448aff;
  }
}
.dialog_box {
  display: flex;
  flex-flow: column;
  .table {
    overflow-y: auto;
    height: 300px;
    margin-top: 10px;
  }
}
.mini_input {
  width: 120px;
  margin-right: 10px;
  ::v-deep .el-input__inner {
    width: 120px;
  }
}
.span_btn_list {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  span {
    cursor: pointer;
  }
}
.card_list {
  overflow-y: auto;
  width: 100%;
  max-height: 75vh;
  /* 滚动条整体部分 */
  &::-webkit-scrollbar {
    width: 3px;
    height: 9px;
  }

  /* 滚动条里面的滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 20px;
    transition: background-color 0.3s;
  }
}
.pagination {
  width: 100%;
  text-align: center;
  margin-top: auto;
  padding: 20px 0;
  box-sizing: border-box;
  background-color: #fff;
}
.new_page {
  margin: 20px auto;
  width: 100%;
  text-align: center;
}
.el-dialog {
  .el-form {
    width: 100%;
    .el-form-item .input_box {
      width: 80%;
      .el-input {
        width: 100%;
      }
    }
  }
}

.color1 {
  color: #f53939;
}
.color2 {
  color: #d4d4d4;
}
.color3 {
  color: #999999;
}
.color4 {
  color: #b8b8b8;
}
.color5 {
  color: #4e93fb;
}
.color6 {
  color: #ff7d18;
}
</style>
